import { Injectable } from '@angular/core';
import { IProductAndPayment, PlansApiService } from './api/plans-api.service';
import { BehaviorSubject, catchError, of } from 'rxjs';
import { Stripe } from 'stripe';
export interface IPlanDescribe {
  header: string;
  subHeader: string;
  body: string;
}
@Injectable({
  providedIn: 'root',
})
export class PlansService {
  FETCH_INTERVAL = 15 * 60 * 1000;
  productsAndPrices$ = new BehaviorSubject<
    { product: Stripe.Product; price: Stripe.Price }[]
  >(null);
  unifiedProduct$ = new BehaviorSubject<IProductAndPayment>(null);
  private lastFetchTime = 0;
  paymentNotifier$ = new BehaviorSubject<boolean>(null);

  constructor(private plansApiService: PlansApiService) {}

  paymentDone(paymentData) {
    this.paymentNotifier$.next(paymentData);
  }

  /**
   *  Fetches the plans and prices from the server.
   *  If we have the plans in the cache, we don't fetch them again unless the cache has expired.
   *
   *  @returns The plans and prices fetched from the server.
   */
  getPlans() {
    const now = Date.now();
    if (
      !this.productsAndPrices$.value?.length ||
      now - this.lastFetchTime > this.FETCH_INTERVAL
    ) {
      this.plansApiService
        .listPlans()
        .pipe(
          catchError((error) => {
            console.error('Error fetching plans:', error);
            // Optionally handle the error and provide a fallback value
            return of(null);
          })
        )
        .subscribe((plans) => {
          plans.sort(
            (a, b) =>
              (a?.price?.unit_amount ?? 0) - (b?.price?.unit_amount ?? 0)
          );
          this.productsAndPrices$.next(plans);
          this.lastFetchTime = now;
        });
    }
  }

  getUnifiedPlan() {
    const now = Date.now();
    if (
      !this.unifiedProduct$.value ||
      now - this.lastFetchTime > this.FETCH_INTERVAL
    ) {
      this.plansApiService
        .unifiedPlan$()
        .pipe(
          catchError((error) => {
            console.error('Error fetching plans:', error);
            // Optionally handle the error and provide a fallback value
            return of(null);
          })
        )
        .subscribe((plan) => {
          this.unifiedProduct$.next(plan);
          this.lastFetchTime = now;
        });
    }
  }
}
